import type { KarrotLocalCountryCode } from "@karrotmarket-com/core";
import { canonicalOrigins, createCanonicalize } from "@karrotmarket-com/core";
import type { RuntimeEnv } from "@karrotmarket-com/env";
import type { MetaDescriptor } from "@remix-run/node";

import { buildRegionParamValue, CommonSearchParamKey } from "../core";

export function canonicalMeta({
  stage,
  karrotLocalCountryCode,
  path,
  region,
  additionalParams,
}: {
  /**
   * 해당 콘텐츠가 소속된 국가 코드
   */
  karrotLocalCountryCode: KarrotLocalCountryCode;

  /**
   * 스테이지
   */
  stage: RuntimeEnv;

  /**
   * 경로를 넣어주세요
   * (알아서 내부에서 Polishing 됩니다)
   */
  path: string;

  /**
   * 지역 맥락이 포함된다면 추가해주세요.
   * (지역 맥락이 포함되지 않는다면 넣지 마세요)
   */
  region?: {
    id: string;
    name: string;
  };

  /**
   * 지역 맥락을 제외하고 포함시킬 search params를 넣어주세요.
   */
  additionalParams?: {
    [key: string]: string | undefined;
  };
}): MetaDescriptor[] {
  const origin = canonicalOrigins[stage][karrotLocalCountryCode];

  const canonicalize = createCanonicalize(stage);
  const { canonicalUrl } = canonicalize({
    url: new URL(origin + path).toString(),
    fallbackCountryCode: karrotLocalCountryCode,
  });

  const u = new URL(canonicalUrl);

  if (region) {
    u.searchParams.set(
      CommonSearchParamKey.In,
      buildRegionParamValue({
        region,
      }),
    );
  }

  if (additionalParams) {
    Object.entries(additionalParams).forEach(([key, value]) => {
      if (value) {
        u.searchParams.set(key, value);
      }
    });
  }

  return [
    { property: "og:url", content: u.toString() },
    { tagName: "link", rel: "canonical", href: u.toString() },
  ];
}
